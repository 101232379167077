

/* CSS reset */
html,body,address,blockquote,div,
form,fieldset,caption,
h1,h2,h3,h4,h5,h6,
hr,ul,li,ol,ul,dl,dt,dd,
table,tr,td,th,p,img, button {
 margin:0;
 padding:0;
 border: none;
}
img, fieldset {
 border:none;
}
*, *:after, *:before {
 box-sizing:border-box;
}


@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@200;400;500;700&display=swap');
/* set the body font size to 62.5% of the user's selected font font size
   For most users, this will set it at 62.5% of 16px, ie 10px - makes calculations easier as 1rem is now 10px
 */
 html {
	font-size: 62.5%
 }
body {
	height: auto;
	overflow: hidden;
	color: white;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	font-family: 'Archivo', sans-serif;
	

	
}

#canvas {
	position: fixed;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	z-index: -1;
}


.NoSelect{
	-moz-user-select: none;
	-webkit-user-select: none;
    -ms-user-select: none;
  	user-select: none;
}


/* For mobie, we need the body to not be scrollable, to avoid showing / hiding bottom bar */
/*  see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
:root {
	--vh: 1vh;
	--bold: 700;
	--medium: 500

}


#content {
	overflow: hidden;
	height: 100% !important;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
button {
	background: none;
	transition: 0.5s
}
p, a {
	color: white;
	font-size: 1.2rem;
}

button:hover , a:hover {
	cursor: pointer;
}

a:hover {
	color: white 
}
button:hover {
	background-color: white;
}


:root {
	--remix-box-margin-side: 4rem;
	--remix-box-padding: 5rem ; 
	--color-green: #84FF23
}

/* Accessibiity helper to hde content off screen but still make it accessible by screen readers
https://www.a11yproject.com/posts/how-to-hide-content/
 */
.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
  }

/* Remix UI container 
*	by default it is hidden, until a .remix class gets added to the body
*/
#RemixBox {

	margin-right: var(--remix-box-margin-side);
	margin-top: var(--remix-box-margin-side);

	display: flex;
	flex-direction: column;
	/* align-items: center; */

	padding: var(--remix-box-padding);
	padding-top: 0 ;
	padding-bottom: 0;

	background-color: rgba(0,0,0,1);
	border: 1px solid white;

	opacity: 0;
	pointer-events: none;
	transition: 0.5s;
	position: relative;
	line-height: 1.2;

}
#RemixBox::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	
	background-color: rgba( 255, 255, 255, 0);
	background-color: rgba( 255, 255, 255, 1);
	z-index: -1;
	transition: 0.5s;
	opacity: 0;
	transform: translate(0,0);
	left: 0.2em;
    top: 0.6em;
	

	
}

.remix #RemixBox {
	opacity: 1;
	pointer-events: all;

}

@keyframes remixBoxIn {
	0% {
		opacity: 0;
		transform: translate(0,0);
	}
	50% {
		opacity: 0;
		transform: translate(0,0);
	}
	100% {
		opacity: 1 ;
		transform: translate(0.7rem,0.7rem);
	}
}
.remix #RemixBox::before{
	animation: remixBoxIn 1s forwards;


}


#RemixBox header {
	display: flex;
	justify-content: space-between;
	width: 100%;


}

/* temp version of the logo is a 960x540 jpeg */
h1 {
	background-image: url('./img/HotDarkMatterLogo.svg');
	background-size: cover;
	background-repeat: no-repeat;
	width: 21.7rem;
	height: calc( 21.7rem * 98/218 );

	

}

/* Button to open UI, not part of the UI box so we can smoothly animate from one to the other */
#UIOpen  {
	position: absolute;
	top: calc( var(--remix-box-margin-side) - 2rem);
	right: calc( var(--remix-box-margin-side) + var(--remix-box-padding) - 3rem );
	transition: 0.5s;
}

#UIOpen svg {
	width: 100%;
	height: 100%
}
#UIOpen svg path {
	transition: 0.5s;
}
#UIOpen:hover > path + path  {
	stroke: var(--color-green);
	fill: var(--color-green);
}
#UIOpen:hover svg > path{
	fill: var(--color-green);
	stroke: var(--color-green);
	
}
#UIOpen:hover svg > g > path{
	fill: black
	
}
.remix #UIOpen {
	opacity: 0;
	pointer-events: none;
}

/* How to export SVG from Figma https://www.youtube.com/watch?v=ZouvuUCytt4 */
/* Then encode as data URI to use inline: https://yoksel.github.io/url-encoder/ */
/* Button to close UI */
#UIClose {
	position: relative;
	/* z-index: 2; */

	margin-left: auto;
	margin-top: -2rem;
	margin-right: -3rem;
	margin-bottom: 1rem;

}

/* we use a span for z-index reasons - without it, the ::before can only be positionned under the remixbox or above it, not between the remix box and the button */
#UIClose span {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42' fill='none'%3E%3Crect x='0.5' y='0.5' width='41' height='41' fill='white' stroke='white'/%3E%3Crect x='0.5' y='0.5' width='41' height='41' fill='black' stroke='white'/%3E%3Cpath d='M24.4066 13.7001L22.4255 15.6812C21.5127 16.5939 20.036 16.5939 19.1233 15.6812L17.1421 13.7001C16.2293 12.7873 14.7527 12.7873 13.8399 13.7001C12.9271 14.6129 12.9271 16.0895 13.8399 17.0022L15.8211 18.9832C16.7339 19.896 16.7339 21.3726 15.8211 22.2853L13.8399 24.2664C12.9271 25.1792 12.9271 26.6557 13.8399 27.5685C14.7527 28.4812 16.2294 28.4812 17.1421 27.5685L19.1233 25.5874C20.0361 24.6746 21.5127 24.6746 22.4255 25.5874L24.4066 27.5685C25.3194 28.4812 26.7961 28.4812 27.7088 27.5685C28.6216 26.6557 28.6216 25.1791 27.7088 24.2664L25.7277 22.2853C24.8149 21.3726 24.8149 19.896 25.7277 18.9832L27.7088 17.0022C28.6216 16.0894 28.6216 14.6128 27.7088 13.7001C26.796 12.7866 25.3194 12.7866 24.4066 13.7001Z' fill='white'/%3E%3C/svg%3E");
	width: 100%;
	height: 100%;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
}

#UIClose::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--color-green);
	top: 0rem;
	left: 0rem;

	transition: 0.5s;
	transform: translate(0rem, 0rem)
	/* z-index: -1; */
}
#UIClose:hover::before {
	transform: translate(0.4rem, 0.4rem)
}
/*  close button */
#UIToggle {
	
}

/* class shared by UI close and oepn buttons */
.UIToggle {

	height: 4rem;
	width: 4rem;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

p , a,  #RemixToggle {
	margin-bottom: 1em;
	/* text-transform: uppercase; */
	letter-spacing: 0.1rem;
	font-weight: 200;
	text-align: left;
}

header + p {
	margin-top: 4em;
	margin-bottom: 1.5em;
}

#RemixBox p:last-of-type {
	margin-bottom: 2em;
}

#mapsLink {
	font-weight: var(--medium);

	text-decoration: underline;
	margin-top: 1em;
	display: block;
	color: #096873
}

.icon {
	background-size: cover;
	width: 1em;
	height: 1em;
	display: inline-block
}
#pinIcon {
	background-image: url('./img/vps_off.png');
}
#menuIcon {
	background-image: url('./img/v.png');
}

#RemixToggle {
	padding: 1.55em 0;
	background-color: black;
	transition: 0.5s;
	color: white;
	border: 1px solid white;
	font-family: 'Archivo Black', sans-serif;
	font-size: 1.5rem;
	width: 100%;
	margin-bottom: 2em;
	position: relative;
	
}

#RemixToggle span {
	position: absolute;
	top: 50%;
	left: 50%;
	transition: 0.5s;
	width: 100%;
	text-align: center;
	transform: translate(-50%, -50%);
}
/* #RemixToggle span:first-child {
	transform: translate(-50%, -50%);
	opacity: 1 
}
#RemixToggle span:last-child {
	transform: translate(-40%, -50%);
	opacity: 0 
} */
#RemixToggle:hover {
	background-color: var(--color-green);
	border: 1px solid var(--color-green);
	color: black
}

/* #RemixToggle:hover span:first-child {
	transform: translate(-40%, -50%);
	opacity: 0 
}
#RemixToggle:hover span:last-child {
	opacity: 1 ;
	transform: translate(-50%, -50%);
	
} */

#Credits {
	font-size: 1rem;
	opacity: 0.8;
	display: flex;
    align-items: center;
	margin-bottom: 2em;
}

#WRLogo {

	height: 1.5em;
	width: calc( 490 / 100 * 1.5em );
	background-image: url('img/LogoWR.png');
	background-size: cover;
	display: inline-block;
	margin-bottom: 0;
	margin-left: 0.5em;
	vertical-align: bottom;
	filter: brightness(0.9);
	transition: 0.5s
}
#WRLogo:hover {
	filter: brightness(1);
}


@media only screen and (max-width: 50em ){
	:root {
	
		--remix-box-padding: 3rem
	}

	#RemixBox {
		margin-top: 4rem;
		margin-left: var(--remix-box-margin-side)
	}
}

@media only screen and (max-width: 30em ){ 
	:root {
		
		--remix-box-padding: 3rem
	}
	#RemixBox {
		margin-left: auto;
		margin-right: auto;
	}
}